import Image from "next/image";
import Link from "next/link";
import useDateLocale from "hooks/useDateLocale";
import { VideosIndexPageQuery } from "@graphql/generated/graphql";
import { formatDistanceToNowStrict } from "date-fns";
import { PlayIcon } from "@heroicons/react/20/solid";

interface IVideoCardProps {
  video: VideosIndexPageQuery["allVideos"][0];
  showPublisher?: boolean;
  showLandingBig?: boolean;
  showLandingSmall?: boolean;
}

const LandingVideoCard: React.FunctionComponent<IVideoCardProps> = (
  videoCardProps
) => {
  const { video } = videoCardProps;

  return (
    <div className="py-4">
      <Link href={`/videos/${video.id}`} passHref>
        <a>
          <div className="hidden md:block">
            <WideCard {...videoCardProps} />
          </div>
          <div className="md:hidden">
            <CompactCard {...videoCardProps} />
          </div>
        </a>
      </Link>
    </div>
  );
};

// used for grid view
export const CompactVideoCard: React.FunctionComponent<IVideoCardProps> = ({
  video,
}) => {
  return (
    <div className="py-4 md:py-1">
      <Link href={`/videos/${video.id}`} passHref>
        <a>
          <CompactCard video={video} />
        </a>
      </Link>
    </div>
  );
};

const CompactCard: React.FunctionComponent<IVideoCardProps> = ({
  video: { imageUrl, title, newsPublisher, pubDate },
  showPublisher = true,
}) => {
  const locale = useDateLocale();

  return (
    <div className="">
      <div className="flex flex-col items-end">
        <div className="aspect-w-16 aspect-h-9 relative h-48 w-full md:h-36">
          <Image
            className="rounded-lg"
            src={imageUrl}
            alt={title}
            layout="fill"
            objectFit="cover"
            priority
          />
        </div>
      </div>

      <p className="mt-4 font-poppins font-bold text-text-darkest hover:text-text-dark">
        {title}
      </p>

      {showPublisher && (
        <div className="mt-2">
          <Link href={`/publishers/${newsPublisher.slug}?view=videos`} passHref>
            <a>
              <div className="flex items-center">
                <div className="relative h-6 w-6 drop-shadow-custom">
                  <Image
                    className="rounded-lg"
                    src={newsPublisher.iconUrl}
                    alt={newsPublisher.name}
                    layout="fill"
                    objectFit="cover"
                    priority
                  />
                </div>

                <p className="ml-2 font-sen text-xs text-text-dark">
                  {newsPublisher.name}
                </p>
              </div>
            </a>
          </Link>
        </div>
      )}

      <div className="mt-3 flex flex-row">
        <p className="flex-1 font-sen text-xs text-disabled">
          {formatDistanceToNowStrict(new Date(pubDate), {
            locale,
            addSuffix: true,
          })}
        </p>
      </div>
    </div>
  );
};

const WideCard: React.FunctionComponent<IVideoCardProps> = ({
  video: { imageUrl, title, newsPublisher, pubDate },
  showPublisher = true,
  showLandingBig,
  showLandingSmall,
}) => {
  const locale = useDateLocale();

  return (
    <div className={showLandingBig ? "flex flex-col" : "flex"}>
      <div className={"flex flex-col items-start"}>
        <div
          className={
            showLandingSmall
              ? "aspect-w-16 aspect-h-9 relative h-9 w-40"
              : "aspect-w-16 aspect-h-9 relative h-full w-full"
          }
        >
          <Image
            className="rounded-lg"
            src={imageUrl}
            alt={title}
            layout="fill"
            objectFit="cover"
            priority
          />
          <div className="absolute inset-0 flex items-center justify-center text-white opacity-0 hover:opacity-70">
            <div className="h-24 w-24">
              <PlayIcon />
            </div>
          </div>
        </div>

        {showLandingBig && showPublisher && (
          <div className="mt-2">
            <Link
              href={`/publishers/${newsPublisher.slug}?view=videos`}
              passHref
            >
              <a>
                <div className="mb-2 mt-2 flex items-center">
                  <div className="relative h-6 w-6 drop-shadow-custom">
                    <Image
                      className="rounded-lg"
                      src={newsPublisher.iconUrl}
                      alt={newsPublisher.name}
                      layout="fill"
                      objectFit="cover"
                      priority
                    />
                  </div>

                  <p className="ml-2 font-sen text-xs text-text-dark">
                    {newsPublisher.name}
                  </p>
                </div>
              </a>
            </Link>
          </div>
        )}
      </div>

      <div
        className={
          showLandingBig
            ? "flex flex-1 flex-col justify-between"
            : "flex flex-1 flex-col justify-between pl-6"
        }
      >
        {showLandingSmall && (
          <div className="mt-2">
            <Link
              href={`/publishers/${newsPublisher.slug}?view=videos`}
              passHref
            >
              <a>
                <div className="mb-2 flex items-center">
                  <div className="relative h-6 w-6 drop-shadow-custom">
                    <Image
                      className="rounded-lg"
                      src={newsPublisher.iconUrl}
                      alt={newsPublisher.name}
                      layout="fill"
                      objectFit="cover"
                      priority
                    />
                  </div>

                  <p className="ml-2 font-sen text-xs text-text-dark">
                    {newsPublisher.name}
                  </p>
                </div>
              </a>
            </Link>
          </div>
        )}
        <div className="font-bold text-text-darkest">
          <p
            className={
              showLandingBig
                ? "font-poppins text-2xl font-bold hover:text-text-dark"
                : "font-poppins text-sm font-bold hover:text-text-dark"
            }
          >
            {title}
          </p>
        </div>
        <div className="mt-4 flex flex-row justify-between">
          <p className="font-sen text-xs font-light text-text-lightest">
            {formatDistanceToNowStrict(new Date(pubDate), {
              locale,
              addSuffix: true,
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingVideoCard;

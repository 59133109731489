import Image from "next/image";
import Share from "@public/paper-plane.svg";
import { DialogName, triggerDialog } from "@components/core/dialog";
import { NewsPageQuery, NewsSummaryFragment } from "@graphql/generated/graphql";
import { ChatBubbleOvalLeftIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import Link from "next/link";

interface INewsActionButtonsProps {
  news: NewsSummaryFragment | NewsPageQuery["news"];
}

const NewsActionButtons: React.FunctionComponent<INewsActionButtonsProps> = ({
  news,
}) => {
  const { locale } = useRouter();
  const openShareDialog = (
    event: React.MouseEvent,
    news: INewsActionButtonsProps["news"]
  ) => {
    event.preventDefault();
    triggerDialog(DialogName.share, {
      id: news.id,
      imageUrl: news.thumbnailUrl,
      title: news.title,
      publisherName: news.newsPublisher.name,
      url: `${window.location.origin}/${locale}/news/${news.id}`,
    });
  };

  const kFormatter = (num: number) => {
    return Math.abs(num) > 999
      ? Math.sign(num) * +(Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  };

  return (
    <div className="flex flex-row space-x-4">
      <a
        className="cursor-pointer"
        onClick={(event) => {
          openShareDialog(event, news);
        }}
      >
        <div
          className="font-sen text-xs font-light text-text-medium"
          id="share-button"
        >
          <div className="mt-[1px] flex flex-row items-center space-x-2">
            <Image src={Share} width={22} height={22} alt="share" />
            <div className="w-6">
              {news.shareCount > 0 && <p>{kFormatter(news.shareCount)}</p>}
            </div>
          </div>
        </div>
      </a>
      <Link href={`/news/${news.id}`}>
        <div
          className="font-sen text-xs font-light text-text-medium"
          id="comment-button"
        >
          <div className="flex flex-row items-center space-x-2">
            <ChatBubbleOvalLeftIcon className="h-6 w-6 text-primary3" />
            <div className="w-6">
              {news.commentsCount > 0 && (
                <p>{kFormatter(news.commentsCount)}</p>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default NewsActionButtons;

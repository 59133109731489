import { enUS, ms, zhCN } from "date-fns/locale";
import { useRouter } from "next/router";

const localeMap = {
  en: enUS,
  zh: zhCN,
  ms: ms,
};

export default function useDateLocale() {
  const router = useRouter();
  const { locale } = router;

  return localeMap[locale];
}
